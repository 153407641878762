var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { size: "sm", centered: "", "hide-footer": "" },
      on: {
        close: function($event) {
          return _vm.onCloseModal()
        },
        hidden: function($event) {
          return _vm.onHiddeModal()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function() {
            return [_vm._v(" Ingresar OTP ")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.modal.isOpen,
        callback: function($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "py-4" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "pb-2", attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Código OTP" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.codigo.$dirty
                            ? !_vm.$v.codigo.$error
                            : null
                        },
                        model: {
                          value: _vm.$v.codigo.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.codigo, "$model", $$v)
                          },
                          expression: "$v.codigo.$model"
                        }
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.codigo)))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { sm: "12" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.sendForm()
                        }
                      }
                    },
                    [_vm._v(" Ingresar ")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center pt-2",
                  attrs: { sm: "12" }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "primary",
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("generarCodigoOtp")
                        }
                      }
                    },
                    [_vm._v(" Reenviar OTP ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }